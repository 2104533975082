<template>
<div class="order-i">
  <div :class="['orderTitle', fromClue ? 'orderTitle_clue' : '']">
    <div class="orderTitle-l">
      <span class="orderTitle-l-row">
        <div class="orderTitle-l-id">{{ orderDetail.orderType===3 ? orderDetail.appOrderId : orderDetail.dcosOrderId }}</div>
        <div v-if="[1,0].includes(orderDetail.installmentsFlag)" class="orderTitle-l-status">{{ orderDetail.installmentsFlag == 1 ? $t('分期') : $t('全款') }}</div>
      </span>
      <span v-if="orderDetail.orderType===1">
        <span class="company">{{$t('大客户')}}</span>
        <span>{{ orderDetail.customerType===1?$t('个人'):orderDetail.customerType===2?$t('企业'):'' }}</span>
      </span>
    </div>
    <div v-if="showOrderCreatedTime" class="orderTitle-r">{{ orderDetail.orderCreatedTime | timeFormat('YYYY-MM-DD HH:mm') }}</div>
  </div>
</div>
</template>
<script>
export default {
  props: {
    orderDetail: {
      type: Object,
      default: () => ({})
    },
    fromClue: {
      type: Boolean,
      default: () => (false)
    },
    showOrderCreatedTime: {
      type: Boolean,
      default: () => (true)
    }
  },
  data(){
    return {
      
    }
  }
}
</script>
<style lang="less" scoped>
.orderTitle{
  padding-bottom: 12px;
  border-bottom: 1px solid #F3F3F4;
  margin-bottom: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  &.orderTitle_clue{
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 8px;
  }
  .orderTitle-l{
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .orderTitle-l-row{
      display: flex;
      flex: 1;
      overflow: hidden;
      .orderTitle-l-id{
        max-width: calc(100% - 44px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .orderTitle-l-status{
        width: 36px;
        margin-left: 8px;
        padding-left: 8px;
        position: relative;
        color: #B9921A;
        box-sizing: border-box;
      }

      .orderTitle-l-status::before{
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 70%;
        background-color: #D9D9D9;
        transform: translateY(-50%);
      }
    }
    .company{
      position: relative;
      padding-right: 8px;
      margin-right: 8px;
    }
    .company::after{
      content: "";
      position: absolute;
      top: 50%;
      right: 0px;
      width: 1px;
      height: 70%;
      background-color: #D9D9D9;
      transform: translateY(-50%);
    }
  }
  .orderTitle-r{
    width: 125px;
    color: rgba(13,23,26,0.45);
    text-align: right;
  }
}
</style>
