<template>
<div
  :ref="`orderItem${dataSource.id}`"
  class="orderItem"
  @click="goDetail"
>
  <OrderTitle :orderDetail="dataSource"></OrderTitle>
  <div class="info">
    <div class="orderStatus">
      <div class="orderStatus-l van-ellipsis">
        <!-- {{
          `${dataSource.carOrderMaterial?.seriesName} ${dataSource.carOrderMaterial?.modelName}`
        }} -->

        {{ dataSource.customerName }} {{ dataSource.customerMobile }}
      </div>
      <div v-if="[2,5].includes(queryType)" class="countDown">{{ countDown }}</div>
      <div
        v-else
        :class="[
          'orderStatus-r',
          'status',
          `STATUS_${dataSource.orderStatus}`,
        ]"
      >
        {{ dataSource.orderStatus | codeFormat }}
      </div>
    </div>
    <!-- <div class="info-i">
      <div class="info-i-t">车身颜色：</div>
      <div class="info-i-c">
        {{ dataSource.carOrderMaterial?.colourName }}
      </div>
    </div> -->
    <!-- <div class="info-i"> -->
    <!-- <div class="info-i-t">内饰颜色：</div>
      <div class="info-i-c">{{ dataSource.carOrderMaterial?.interiorName }}</div> -->
    <!-- <div class="amount"><span content="¥"></span>{{ `${formatRMB(dataSource.totalAmount)}` }}</div> -->
    <!-- </div> -->
    <div class="info-i">
      <div class="info-i-t">
        {{
          `${dataSource.carOrderMaterial?.seriesName} ${dataSource.carOrderMaterial?.modelName}`
        }}
      </div>
      <!-- <div class="info-i-t">客户信息：</div> -->
      <!-- <div class="info-i-c">
        <div class="phone">{{ dataSource.customerMobile }}</div>
        <div v-if="dataSource.customerName" class="name">
          {{ dataSource.customerName }}
        </div>
      </div> -->
      <div class="amount">
        <span v-if="formatRMB(dataSource.totalAmount)" content="¥"></span>{{ `${formatRMB(dataSource.totalAmount)}` }}
      </div>
    </div>
    <div class="info-i">
      <div class="info-i-t">
        <span v-if="dataSource.coVehicleSource === 2" class="sepcial-car">{{ dataSource.coVehicleSource === 2 ? '特殊车（非一车一价）' : '' }}</span>
      </div>
    </div>
    <div v-if="showOrderUpdateInfo" class="info-i" style="color: #E4002C">
      <div class="info-i-t" style="color: #E4002C">{{dataSource.abnormalType === 1 ? $t('订单修改申请：') : $t('退单申请：')}}</div>
      <div class="info-i-c">
        {{ dataSource.orderUpdateStatus | codeFormat }}
      </div>
    </div>
    <van-checkbox
      v-if="isDistributionState"
      :value="dataSource.checked"
      shape="square"
      class="check-item"
      @click.native.stop="onClick"
    >
    </van-checkbox>
  </div>
  <div class="bottom">
    <div class="follow-user">
      {{$t('销售顾问：')}}{{ dataSource.salesUserName || "--" }}
    </div>
    <div class="follow-user">
      {{$t('交付专员：')}}{{ dataSource.deliveryUserName || "--" }}
    </div>
    <!-- <div v-if="i18n.locale.includes('zh')" class="follow-user"></div> -->
    <!-- 销售助手-待办需要添加按钮 -->
    <slot name="btns"></slot>
  </div>
</div>
</template>
<script>
import OrderTitle from './orderTitle'
import moment from 'moment'
import dayjs from 'dayjs'
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import { i18n } from '@/main'
export default {
  components: {
    OrderTitle,
    externalUserIcon,
  },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    isDistributionState: {
      type: Boolean,
      default: false,
    },
    queryType: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      i18n
    }
  },
  computed: {
    showIcon() {
      return true
    },
    countDown() {
      let time = ''
      const { orderStatusUpdateTime } = this.dataSource
      if (!orderStatusUpdateTime) return false
      time = -dayjs().diff(
        dayjs(orderStatusUpdateTime).add(this.queryType === 2 ? 72 : 24, 'hours'),
        'seconds'
      )
      return time < 0
        ? this.$t('倒计时0小时')
        : `${this.$t('倒计时')}${Math.ceil(moment.duration(time, 'seconds').asHours())}${this.$t('小时')}`
    },
    // 展示订单修改/退单信息
    showOrderUpdateInfo() {
      const { orderUpdateStatus } = this.dataSource
      return (
        orderUpdateStatus && !['3001000', '3001035'].includes(orderUpdateStatus)
      )
    },
  },
  methods: {
    selectUser(params = {}) {
      const { externalUserid, name } = params
      this.$set(this.dataSource, 'customerName', name)
      this.$set(this.dataSource, 'customerWeChat', externalUserid)
    },
    onClick() {
      this.$emit('change', !this.dataSource.checked, this.dataSource)
    },
    goDetail(){
      this.$router.push({
        path: '/order-detail',
        query: {
          id: this.dataSource.id,
        },
      })
      // this.$emit('goDetail')
    }
  },
}
</script>
<style lang="less" scoped>
.orderItem {
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px 12px 0px 12px;
  font-size: 13px;
  .van-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .info {
    position: relative;
    .orderStatus {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 25px);
      .orderStatus-l {
        flex: 1;
        font-size: 16px;
        line-height: 16px;
        color: #323232;
      }
      .countDown {
        color: #029640;
      }
    }
    > div {
      margin-bottom: 8px;
    }
    .info-i {
      display: flex;
      position: relative;
      width: calc(100% - 25px);
      .info-i-t {
        color: rgba(13, 23, 26, 0.45);
        min-width: 65px;
        .sepcial-car {
          padding: 2px 8px;
          border-radius: 4px;
          border-width: 1px;
          border-style: solid;
          line-height: 16px;
          font-size: 12px;
          border-color: rgba(0, 64, 197, 0.1);
          color: #ee0a24;
          background: #ee0a2421;
        }
      }
      .info-i-c {
        display: flex;
        flex: 1;
        overflow: hidden;
        .phone {
          max-width: 92px;
        }
        .name {
          margin-left: 8px;
          padding-left: 8px;
          position: relative;
          width: calc(100% - 183px);
          // flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .name::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 70%;
          background-color: #d9d9d9;
          transform: translateY(-50%);
        }
      }
      .amount {
        position: absolute;
        right: 0px;
        // color: #E4002C;
        color: #b9921a;
        font-size: 13px;
        background: white;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(13, 23, 26, 0.05);
    padding: 8px 0;
    .follow-user {
      font-size: 13px;
      color: #0d171a;
      line-height: 20px;
      flex: 1;
    }
    .icons {
      display: flex;
      padding: 8px 0 12px 0;
      justify-content: space-between;
      .icon {
        margin-left: 16px;
      }
    }
  }
  .check-item {
    position: absolute;
    right: 0px;
    top: calc(50% - 8px);
    z-index: 1;
    /deep/.van-checkbox__icon {
      font-size: 16px;
      .van-icon {
        border: 2px solid rgba(13, 23, 26, 0.45);
      }
    }
    /deep/.van-checkbox__icon--checked .van-icon {
      background-color: #0d171a;
      border-color: #0d171a;
      line-height: 1;
    }
  }
  .ml-16 {
    margin-left: 16px;
  }
}
</style>
