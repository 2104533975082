<template>
  <div class="optionsBox">
    <slot name="before"></slot>
    <div v-for="item in optionsList" :key="item.value" :class="['option', subType === item.value ? 'active' : '']" @click="change(item)">
      <template v-if="showCount">{{ `${item.text} (${item.total})` }}</template>
      <template v-else>{{ item.text }}</template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'optionsBoxComponent',
  props: {
    optionsList: {
      type: Array,
      default:() => []
    },
    subType: {
      type: Number,
      default: () => null
    },
    showCount: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    change(item) {
      this.$emit('change', item.value)
    }
  }
}
</script>
<style lang="less" scoped>
.optionsBox{
  display: flex;
  width: 100%;
  height: auto;
  overflow-x: auto;
  padding: 8px 16px;
  .option{
    white-space: nowrap;
    box-sizing: border-box;
    padding: 4px 12px;
    border-radius: 50px;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
    font-size: 12px;
    color: @black;
    background: #fff;
    &:not(:first-child) {
      margin-left: 6px;
    }
    &.active{
      color: #fff;
      background: #B9921A;
    }
  }
}
</style>
