<script>
import bindImg from '@/images/bind.png'
import baseDataServices from '@/services/baseDataServices'
export default {
  props:{
    name:{
      type: String,
      default:''
    },
    // 线索id
    id:{
      type: [Number,String],
      default:''
    },
    isShowImg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      bindImg
    }
  },
  methods:{
    getExternalDetail(externalUserId) {
      return baseDataServices.getExternalDetail({ externalUserId })
    },
    setWechat(params) {
      return baseDataServices.setWechat(params)
    },
    selectExternalContact() {
      window.wx.invoke(
        'selectExternalContact',
        {
          filterType: 0,
        },
        async (res) => {
          if (res.err_msg != 'selectExternalContact:ok') return
          const { userIds } = res
          if (!userIds || userIds.length !== 1) {
            return this.$toast(this.$t('请选择一个外部联系人进行绑定'))
          }
          const [userUuid] = userIds
          const customer = await this.getExternalDetail(userUuid)
          if (this.id){
            await this.setWechat({
              id: this.id,
              userAvatar:customer.avatar,
              userWeChat:customer.externalUserid,
              userWeChatName: customer.name
            })
            this.$toast(this.$t('操作成功'))
          }
          setTimeout(() => {
            this.$emit('selectUser',customer)
          }, 1000)
        }
      )
    }
  }
}
</script>
<template>
<div class="img" @click.stop="selectExternalContact">
  <img v-if="isShowImg" :src="bindImg"/>
  <span v-if="name" class="name" style="margin-left:8px;">{{ name }}</span>
</div>
</template>
<style lang="less" scoped>
.img{
  cursor: pointer;
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 32px;
    &:hover,&:active{
      box-shadow: 0px 2px 5px #21212138;
      border-radius: 50%;
    }
  }
}
</style>
